import Notepad from '@/components/dialogs/Notepad';
import { NotePencil } from '@/components/icons';
import HeartIcon from '@/components/icons/SvgIcons/HeartIcon';
import {
  FAB_POS_KEY,
  useDragLocalStorage,
  useNoteButton,
} from '@/hooks/useFloatingNotes';
import useLaunchDarkly from '@/hooks/useLaunchDarkly';
import { IconButton, styled } from '@mui/material';
import { featureFlagIds } from 'config';
import { useState } from 'react';
import Draggable, { DraggableData } from 'react-draggable';

const StyledButton = styled(IconButton)(({ theme }) => {
  const spacing2 = theme.spacing(2);
  const color = theme.palette.info;
  return {
    position: 'absolute',
    display: 'flex',
    bottom: theme.spacing(8),
    left: theme.spacing(2),
    zIndex: theme.zIndex.modal,
    backgroundColor: color[600],
    ':hover': {
      backgroundColor: color[500],
    },
    '&.Mui-disabled': {
      backgroundColor: color[300],
      border: `1px solid ${color[300]}`,
    },
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(1),
    border: `1px solid ${color[700]}`,
    padding: spacing2,
    '.MuiSvgIcon-root': {
      width: spacing2,
      height: spacing2,
    },
  };
});

const StyledButtonEasterEgg = styled(StyledButton)(({ theme }) => {
  const color = theme.palette.pink;
  return {
    backgroundColor: color.main,
    ':hover': {
      backgroundColor: color.light,
    },
    '&.Mui-disabled': {
      backgroundColor: color.light,
      border: `1px solid ${color.light}`,
    },
    border: `1px solid ${color.dark}`,
  };
});

export default function NoteButton() {
  // using localStorage to manage the state directly
  const { handleDrag, handleStop, currentPosition } = useDragLocalStorage(
    FAB_POS_KEY,
    100,
    150,
  );
  const [open, setOpen] = useNoteButton();
  const [disabled, setDisabled] = useState(false);
  const { flags } = useLaunchDarkly();
  const isEasterEggOn = flags[featureFlagIds.enableEasterEgg];

  function handleFabClick() {
    setOpen(open => !open);
  }

  // disable the button when it starts moving
  // to prevent a click on mouse up
  function onDrag(data: DraggableData) {
    handleDrag(data.x, data.y);
    setDisabled(!!data.deltaX || !!data.deltaY);
  }
  function onStop(data: DraggableData) {
    handleStop(data.x, data.y);
    setDisabled(false);
  }

  return (
    <>
      <Draggable
        handle='#notes-button'
        bounds='body'
        onDrag={(_, data) => onDrag(data)}
        onStop={(_, data) => onStop(data)}
        position={currentPosition}
      >
        {isEasterEggOn ? (
          <StyledButtonEasterEgg
            id='notes-button'
            onClick={handleFabClick}
            disabled={disabled}
            aria-label='notes-button'
          >
            <HeartIcon />
          </StyledButtonEasterEgg>
        ) : (
          <StyledButton
            id='notes-button'
            onClick={handleFabClick}
            disabled={disabled}
            aria-label='notes-button'
          >
            <NotePencil />
          </StyledButton>
        )}
      </Draggable>
      {open && (
        <Notepad handleClose={handleFabClick} isEasterEggOn={isEasterEggOn} />
      )}
    </>
  );
}
