import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { ControlPosition } from 'react-draggable';

export const NOTE_KEY = 'formbio-notes';
export const NOTE_POS_KEY = 'formbio-notes-pos';
export const FAB_POS_KEY = 'formbio-fab-pos';
const FAB_OPEN_KEY = 'formbio-fab-open';
const DEFAULT_POSITION = { x: 0, y: 0 };

/**
 * Hook manages the string content and
 * the coordinates of the floating notes.
 * Saves notes and coordinates in localStorage.
 * Needs width and height to keep the component bound
 * to the page (avoids extending the viewport and scrollbars)
 */
export default function useFloatingNotes() {
  const [noteContent, setNoteContent] = useState<string>(loadTextContent);

  useEffect(() => {
    window.addEventListener('focus', syncNotes);
    window.addEventListener('blur', flush);

    return () => {
      window.removeEventListener('focus', syncNotes);
      window.removeEventListener('blur', flush);
    };
  }, []);

  function loadTextContent() {
    return window.localStorage.getItem(NOTE_KEY) || '';
  }

  function saveTextContent(value: string) {
    window.localStorage.setItem(NOTE_KEY, value);
  }

  const debouncedInputHandler = useMemo(() => {
    return debounce(saveTextContent, 500);
  }, []);

  function handleNoteChange(value: string) {
    setNoteContent(value);
    debouncedInputHandler(value);
  }

  /**
   * Sync notes between instances (if multiple tabs),
   * for example on focus.
   * Needs a tiny delay in the rare cases that
   * the user clicks really fast between tabs
   * so the localStorage is up-to-date
   */
  function syncNotes() {
    setTimeout(() => setNoteContent(loadTextContent()), 100);
  }

  function flush() {
    // save latest value on unmount
    debouncedInputHandler.flush();
  }

  useEffect(() => {
    return () => {
      flush();
      // cancel the debounce on unmount
      debouncedInputHandler.cancel();
    };
  }, []);

  return {
    handleNoteChange,
    noteContent,
  };
}

/**
 * Since both the button and the notepad use this hook
 * make sure the key param is different for each
 * Use this hooks global const keys.
 */
export function useDragLocalStorage(key: string, offsetX = 100, offsetY = 100) {
  const [currentPosition, setCurrentPosition] =
    useState<ControlPosition>(loadLastPosition);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [offsetX, offsetY]);

  function handleDrag(xPos: number, yPos: number) {
    setCurrentPosition({ x: xPos, y: yPos });
  }

  function handleStop(xPos: number, yPos: number) {
    window.localStorage.setItem(key, JSON.stringify({ x: xPos, y: yPos }));
  }

  function loadLastPosition(): ControlPosition {
    const lastPos = window.localStorage.getItem(key);
    if (lastPos) {
      const parsed = JSON.parse(lastPos);
      // make sure the values are valid
      if (isNaN(parsed.x) || isNaN(parsed.y)) {
        return DEFAULT_POSITION;
      }
      return parsed;
    }
    return DEFAULT_POSITION;
  }

  function handleWindowResize() {
    const { x: currentPositionX, y: currentPositionY } = loadLastPosition();
    const safeWidth = window.innerWidth - offsetX;
    const safeX = safeWidth < currentPositionX ? safeWidth : currentPositionX;
    const safeHeight = -window.innerHeight + offsetY;
    const safeY = safeHeight > currentPositionY ? safeHeight : currentPositionY;
    // only save if there'
    if (safeX !== currentPositionX || safeY !== currentPositionY) {
      handleDrag(safeX, safeY);
      handleStop(safeX, safeY);
    }
  }

  return {
    handleDrag,
    handleStop,
    currentPosition,
  };
}

export function useNoteButton(): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [open, setOpen] = useState<boolean>(loadOpenState);

  function loadOpenState() {
    return !!window.localStorage.getItem(FAB_OPEN_KEY);
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (open) {
      window.localStorage.setItem(FAB_OPEN_KEY, 'open');
    } else {
      window.localStorage.removeItem(FAB_OPEN_KEY);
    }
  }, [open]);

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  return [open, setOpen];
}
