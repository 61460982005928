import { CopySimple, MinusIcon } from '@/components/icons';
import HeartIcon from '@/components/icons/SvgIcons/HeartIcon';
import ValentineCard from '@/components/special/ValentineCard';
import { useNotificationContext } from '@/context/notificationContext';
import useFloatingNotes, {
  NOTE_POS_KEY,
  useDragLocalStorage,
} from '@/hooks/useFloatingNotes';
import {
  Card,
  CardContent,
  CardHeader,
  styled,
  Typography,
  IconButton,
  TextField,
  CardActions,
  Button,
  Tooltip,
} from '@mui/material';
import { useRef, useState } from 'react';
import Draggable, { DraggableData } from 'react-draggable';

const WIDTH = 320;
const MAX_LENGTH = 5000;

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'absolute',
  padding: theme.spacing(1),
  // make sure it's within the current page limits
  bottom: theme.spacing(8),
  width: WIDTH,
  zIndex: theme.zIndex.modal,
  '#notes': {
    cursor: 'move',
  },
  '& .MuiCardHeader-root': {
    paddingBottom: 0,
    '.MuiTypography-root': {
      ...theme.typography.body2,
      fontWeight: 600,
    },
  },
  '& .MuiOutlinedInput-input': {
    ...theme.typography.body2,
  },
  '& .MuiCardActions-root': {
    justifyContent: 'end',
    paddingTop: 0,
  },
}));

const StyledCardEasterEgg = styled(StyledCard)(({ theme }) => {
  const color = theme.palette.pink;
  return {
    // root header and actions backgroundColor
    '&, .MuiCardHeader-root, .MuiCardActions-root': {
      backgroundColor: color.light,
    },
    '.MuiCardActions-root': {
      justifyContent: 'space-between',
    },
    '.pink': {
      color: color.main,
    },
  };
});

export default function Notepad({
  handleClose,
  isEasterEggOn,
}: {
  handleClose?: () => void;
  isEasterEggOn?: boolean;
}) {
  const notepadRef = useRef<HTMLDivElement>(null);
  const { setSnackbar } = useNotificationContext();
  const { handleNoteChange, noteContent } = useFloatingNotes();
  const { handleDrag, handleStop, currentPosition } = useDragLocalStorage(
    NOTE_POS_KEY,
    (notepadRef.current?.clientWidth || 320) * 1.2,
    (notepadRef.current?.clientHeight || 300) * 1.2,
  );
  const CardComponent = isEasterEggOn ? StyledCardEasterEgg : StyledCard;
  const [startValentine, setStartValentine] = useState(false);

  function handleNoteContentChange(value: string) {
    handleNoteChange(value);
  }

  function onDrag(data: DraggableData) {
    handleDrag(data.x, data.y);
  }

  function onStop(data: DraggableData) {
    handleStop(data.x, data.y);
  }

  function onClose() {
    handleClose?.();
  }

  async function handleCopyAll() {
    await navigator.clipboard.writeText(noteContent || '');
    setSnackbar({
      autoHideDuration: 5000,
      variant: 'info',
      children: 'Notes Copied',
    });
  }

  function handleEasterEgg() {
    setStartValentine(startValentine => !startValentine);
  }

  return (
    <>
      <Draggable
        handle='#notes'
        position={currentPosition}
        onDrag={(_, data) => onDrag(data)}
        onStop={(_, data) => onStop(data)}
        bounds='body'
      >
        <CardComponent elevation={10} ref={notepadRef}>
          <CardHeader
            id='notes'
            title={
              <Typography component='label' htmlFor='note-area'>
                Quick Notes
              </Typography>
            }
            action={
              <IconButton onClick={onClose} size='small' aria-label='close'>
                <MinusIcon fontSize='small' />
              </IconButton>
            }
          />
          <CardContent>
            <TextField
              id='note-area'
              multiline
              rows={6}
              fullWidth
              placeholder='Type notes here'
              aria-label='note-area'
              value={noteContent}
              onChange={event => handleNoteContentChange(event.target.value)}
              inputProps={{ maxLength: MAX_LENGTH }}
            />
          </CardContent>
          <CardActions>
            {isEasterEggOn && (
              <Tooltip title='Will you be my Valentine?'>
                <IconButton onClick={handleEasterEgg} className='pink'>
                  <HeartIcon />
                </IconButton>
              </Tooltip>
            )}
            <Button startIcon={<CopySimple />} onClick={handleCopyAll}>
              Copy All
            </Button>
          </CardActions>
        </CardComponent>
      </Draggable>
      {startValentine && <ValentineCard handleClose={handleEasterEgg} />}
    </>
  );
}
