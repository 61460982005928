import { styled, Stack } from '@mui/material';
import { random } from 'lodash';
import { useEffect, useRef, useState } from 'react';

const defaultColor = '#E93D82';
const lightPink = '#FCE5F0';
const letters1 = 'All of Form Bio is wishing you a'.split('');
const letters2 = "Happy Valentine's Day!".split('');
const maxIndex = letters1.length + letters2.length;
const writingSpeed = 50; // ms to simulate typing
const startX = 100;
const maxFrames = 1000; // number of hearts
const maxHeartSize = 50;

const StyledBox = styled('div')(() => ({
  width: '100%',
  height: '100%',
}));
const TextContainer = styled(Stack)(({ theme }) => {
  const offset = '1px';
  const diffusion = '5px';
  const color = '#FFF';
  return {
    position: 'absolute',
    top: '40%',
    left: startX,
    ...theme.typography.body1,
    fontWeight: 600,
    // white shadow around each character to improve visibility
    textShadow: `-${offset} -${offset} ${diffusion} ${color}, ${offset} -${offset} ${diffusion} ${color}, -${offset} ${offset} ${diffusion} ${color}, ${offset} ${offset} ${diffusion} ${color}`,
  };
});

function buildHeartSvg({
  size,
  color = defaultColor,
  fill = 'none',
  angle = 0,
}: {
  size: number;
  color?: string;
  fill?: string;
  angle?: number;
}) {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 32 32" stroke="${color}" fill="${fill}" transform="rotate(${angle})">
  <path  d="M22 4C20.8357 4 19.6873 4.27109 18.6459 4.7918C17.6045 5.3125 16.6986 6.06853 16 7C15.0555 5.74071 13.7388 4.8105 12.2363 4.34115C10.7337 3.8718 9.12163 3.8871 7.62829 4.38488C6.13495 4.88266 4.83609 5.83768 3.91568 7.11467C2.99528 8.39166 2.5 9.92588 2.5 11.5C2.5 20.4875 14.9875 27.575 15.5125 27.875C15.6613 27.9586 15.8294 28.0017 16 28C16.1708 28.0029 16.3392 27.9597 16.4875 27.875C18.7871 26.5306 20.944 24.9559 22.925 23.175C27.2875 19.25 29.5 15.325 29.5 11.5C29.5 9.51088 28.7098 7.60322 27.3033 6.1967C25.8968 4.79018 23.9891 4 22 4V4Z" fill="${fill}"/>
  </svg>
  `;
}

function getMinSize(max: number) {
  return random(5, max, false);
}

function getPosition(max: number) {
  return random(0, max, false);
}

function getAngle() {
  return random(-180, 180, false);
}

function getColor(frameCount: number) {
  if (frameCount % 4 === 0) {
    return defaultColor;
  }
  if (frameCount % 3 === 0) {
    return lightPink;
  }
  return 'none';
}

function draw({
  ctx,
  positionX,
  positionY,
  imageSrc,
}: {
  ctx: CanvasRenderingContext2D;
  positionX: number;
  positionY: number;
  imageSrc: string;
}) {
  const image = new Image();
  image.src = imageSrc;
  image.onload = () => {
    ctx.drawImage(image, positionX, positionY);
  };
}

export default function ValentineContent() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [currentText1, setCurrentText1] = useState('');
  const [currentText2, setCurrentText2] = useState('');
  let animationFrameId: number;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    // simulates typing by incrementing
    // text one char at a time at {writingSpeed}
    function writeText(index: number) {
      if (index <= letters1.length) {
        // text1
        timeout = setTimeout(() => {
          setCurrentText1(letters1.slice(0, index).join(''));
          writeText(index + 1);
        }, writingSpeed);
      } else if (index <= maxIndex) {
        const index2 = index - letters1.length;
        // text2 after text1 is done
        timeout = setTimeout(() => {
          setCurrentText2(letters2.slice(0, index2).join(''));
          writeText(index + 1);
        }, writingSpeed);
      } else {
        setTimeout(() => {
          startDrawing();
        }, 500);
      }
    }
    setTimeout(() => {
      writeText(0);
    }, 500);
    return () => {
      timeout && clearTimeout(timeout);
      animationFrameId && window.cancelAnimationFrame(animationFrameId);
    };
  }, []);

  function startDrawing() {
    let frameCount = 0;
    const context = canvasRef.current?.getContext('2d');
    if (!context) {
      return;
    }
    const clientWidth = boxRef.current?.clientWidth || 500;
    const clientHeight = boxRef.current?.clientHeight || 500;
    // prevents resetting the canvas after the 1st adjustment
    if (
      canvasRef.current &&
      canvasRef.current.width !== clientWidth &&
      canvasRef.current.height !== clientHeight
    ) {
      canvasRef.current.width = clientWidth;
      canvasRef.current.height = clientHeight;
    }
    const render = () => {
      frameCount++;
      // stop above 1000 frames
      if (frameCount < maxFrames) {
        // small hearts with varying sizes
        const imageSrc = `data:image/svg+xml;base64,${window.btoa(
          buildHeartSvg({
            size: getMinSize(maxHeartSize),
            angle: getAngle(),
            fill: getColor(frameCount),
          }),
        )}`;
        draw({
          ctx: context,
          positionX: getPosition(clientWidth),
          positionY: getPosition(clientHeight),
          imageSrc,
        });
        animationFrameId = window.requestAnimationFrame(render);
      }
    };
    render();
  }

  return (
    <StyledBox ref={boxRef}>
      <canvas ref={canvasRef} />
      <TextContainer spacing={2}>
        <div>{currentText1}</div>
        <div>{currentText2}</div>
      </TextContainer>
    </StyledBox>
  );
}
