import { SvgIcon, SvgIconProps } from '@mui/material';

export default function HeartIcon(props: SvgIconProps) {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' {...props}>
      <path
        d='M22 4C20.8357 4 19.6873 4.27109 18.6459 4.7918C17.6045 5.3125 16.6986 6.06853 16 7C15.0555 5.74071 13.7388 4.8105 12.2363 4.34115C10.7337 3.8718 9.12163 3.8871 7.62829 4.38488C6.13495 4.88266 4.83609 5.83768 3.91568 7.11467C2.99528 8.39166 2.5 9.92588 2.5 11.5C2.5 20.4875 14.9875 27.575 15.5125 27.875C15.6613 27.9586 15.8294 28.0017 16 28C16.1708 28.0029 16.3392 27.9597 16.4875 27.875C18.7871 26.5306 20.944 24.9559 22.925 23.175C27.2875 19.25 29.5 15.325 29.5 11.5C29.5 9.51088 28.7098 7.60322 27.3033 6.1967C25.8968 4.79018 23.9891 4 22 4Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
