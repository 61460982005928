import NoteButton from '@/components/buttons/NoteButton';
import { useAuth } from '@/hooks/auth';
import useLaunchDarkly from '@/hooks/useLaunchDarkly';
import { Box, LinearProgress } from '@mui/material';
import { featureFlagIds } from 'config';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { navRoutes } from './routes';

export default function withRequiredAuth<T extends object>(
  WrappedComponent: React.ComponentType<T>,
) {
  return function WithRequiredAuth(props: T) {
    const router = useRouter();
    const { user, loading } = useAuth();
    const { flags } = useLaunchDarkly();

    useEffect(() => {
      if (!loading && !user) {
        router.push({
          pathname: navRoutes.login.pathname,
          query: { destination: router.asPath },
        });
      }
    }, [loading, user, router.asPath]);

    if (loading || !user) {
      return (
        <Box sx={{ padding: '1em' }}>
          <LinearProgress />
        </Box>
      );
    }
    return (
      <>
        <WrappedComponent {...props} />
        {flags[featureFlagIds.enableQuickNotes] && <NoteButton />}
      </>
    );
  };
}
