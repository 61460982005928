import { CloseIcon } from '@/components/icons';
import ValentineContent from '@/components/special/ValentineContent';
import { useAuth } from '@/hooks/auth';
import {
  Card,
  CardContent,
  CardHeader,
  styled,
  Typography,
  IconButton,
} from '@mui/material';
import Draggable from 'react-draggable';

const StyledValentineContent = styled(Card)(({ theme }) => ({
  position: 'absolute',
  padding: theme.spacing(1),
  // make sure it's within the current page limits
  top: 0,
  width: '500px',
  zIndex: theme.zIndex.modal,
  '& .MuiCardHeader-root': {
    paddingBottom: 0,
    '.MuiTypography-root': {
      ...theme.typography.body2,
      fontWeight: 600,
    },
  },
  '& .MuiCardContent-root': {
    height: '500px',
  },
  '& .MuiCardActions-root': {
    justifyContent: 'space-between',
  },
}));

export default function ValentineCard({
  handleClose,
}: {
  handleClose: () => void;
}) {
  const { user } = useAuth();

  return (
    <Draggable bounds='body' defaultPosition={{ x: 100, y: 100 }}>
      <StyledValentineContent>
        <CardHeader
          id='notes'
          title={
            <Typography component='label' htmlFor='note-area'>
              To {user?.displayName?.split(' ')[0] || 'you, stranger'}
            </Typography>
          }
          action={
            <IconButton onClick={handleClose} size='small' aria-label='close'>
              <CloseIcon fontSize='small' />
            </IconButton>
          }
        />
        <CardContent>
          <ValentineContent />
        </CardContent>
      </StyledValentineContent>
    </Draggable>
  );
}
